import { Environment } from "@envato/sso-common";

import { trackClickEvent, trackAuthEvent } from "./track";

export interface GTMOptions {
  containerId: string;
  preview?: string;
  auth?: string;
  nonce: string;
  env?: Environment;
}

const buildGTMSrc = (options: GTMOptions) => {
  const src = new URL(`https://www.googletagmanager.com/gtm.js`);
  src.searchParams.append("id", options.containerId);
  if (options.env !== "production") {
    src.searchParams.append("gtm_auth", options.auth || "");
    src.searchParams.append("gtm_preview", options.preview || "");
    src.searchParams.append("gtm_cookies_win", "x");
  }
  return src.toString();
};

const initialize = (options: GTMOptions) => {
  if (!document.getElementById("gtm-script")) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      "gtm.start": new Date().getTime(),
      event: "gtm.js",
    });
    const script = document.createElement("script");
    script.setAttribute("id", "gtm-script");
    script.setAttribute("src", buildGTMSrc(options));
    script.setAttribute("type", "text/javascript");
    script.setAttribute("async", "true");
    script.setAttribute("nonce", options.nonce);

    const head = document.head;
    if (head) {
      head.insertBefore(script, head.firstChild);
    }
  }
};

const pushToDataLayer = (data: object) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(data);
};

export type PageViewPayload = {
  sourceVersion?: string;
  env?: Environment;
  pageType?: AnalyticsPageType;
  gaParam?: string | null;
  currentUserUuid?: string;
};

const pushPageAttributes = (payload: PageViewPayload) => {
  pushToDataLayer({
    app_name: "account",
    app_version: payload.sourceVersion,
    app_env: payload.env,
    page_type: payload.pageType,
    ga_param: payload.gaParam,
    event_attributes: null,
    ...(payload.currentUserUuid && {
      user_attributes: {
        user_id: payload.currentUserUuid,
      },
    }),
  });
};

const sendAnalyticsReadyEvent = () => {
  pushToDataLayer({
    event: "analytics_ready",
    event_attributes: {
      event_type: "user",
      custom_timestamp: Date.now(),
    },
  });
};

const trackPageViewEvent = (payload: PageViewPayload) => {
  pushPageAttributes(payload);

  sendAnalyticsReadyEvent();
};

const Wrapper = {
  initialize,
  trackPageViewEvent,
  trackClickEvent,
  trackAuthEvent,
};

export default Wrapper;
