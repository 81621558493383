import {
  extractLinkAttributes,
  sendAnalyticsEvent,
} from "@envato/gtm-analytics";

import { ClickEvent } from "@envato/sso-analytics";

const extractTextContent = (element: HTMLButtonElement | HTMLAnchorElement) =>
  [
    element?.textContent?.trim(),
    element?.getAttribute("title")?.trim(),
    element?.getAttribute("aria-label")?.trim(),
    element?.getAttribute("alt")?.trim(),
  ].find(Boolean);

const extractClickTrackingData = (
  browserEvent: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>,
) => {
  const element = browserEvent?.currentTarget;
  const linkAttributes = {
    eventName: "internal_click",
    ...extractLinkAttributes(element),
  };

  return {
    textContent: extractTextContent(element),
    ...linkAttributes,
  };
};

const trackClickEvent = (props: ClickEvent) => {
  const clickTrackingData = extractClickTrackingData(props.browserEvent);

  const payload = {
    eventType: "user",
    elementId: props.trackingId,
    elementLabel: props.trackingLabel,
    ...clickTrackingData,
  };

  sendAnalyticsEvent(payload);
};

export default trackClickEvent;
