import React, { useCallback, useEffect, useMemo, useState } from "react";

import {
  AnalyticsEvent,
  AuthEvent,
  ClickEvent,
  TrackedEventFunctions,
} from "@envato/sso-analytics";
import { useConfig, useCspNonce } from "@envato/sso-common";

import { useCookiebot } from "../withCookiebot";
import { useRouteMetaData } from "../withRouteMetaData/RouteMetaDataContext";

import Wrapper, { GTMOptions, PageViewPayload } from "./Wrapper";
import { extractGaParameter } from "../../utils/analytics";

type ProviderProps = {
  children?: React.ReactNode;
};

const Provider: React.FC<ProviderProps> = ({ children }) => {
  const [gtmLoaded, setGtmLoaded] = useState(false);
  const [processedServerEvents, setProcessedServerEvents] = useState(false);
  const [eventQueue, setEventQueue] = useState<AnalyticsEvent[]>([]);
  const scriptNonce = useCspNonce();
  const [
    {
      analyticsEnabled,
      cookiebotEnabled,
      currentUser,
      environment,
      googleTagManager,
      serverAnalyticsEvents,
      sourceVersion,
    },
  ] = useConfig();
  const [config, setConfig] = useConfig();
  const { analyticsPageType } = useRouteMetaData();
  const { containerId, auth, preview } = googleTagManager || {};
  const [{ loaded: cookiebotLoaded, consent }] = useCookiebot();
  const performClickTracking = useCallback(
    (newEvent: ClickEvent) => Wrapper.trackClickEvent(newEvent),
    [],
  );

  const performAuthTracking = useCallback(
    (newEvent: AuthEvent) => Wrapper.trackAuthEvent(newEvent),
    [],
  );

  useEffect(() => {
    if (gtmLoaded) {
      eventQueue.forEach((event) => {
        switch (event.type) {
          case "click":
            performClickTracking(event as ClickEvent);
            break;
          case "auth":
            performAuthTracking(event as AuthEvent);
            break;
          default:
            break;
        }
      });
    }
  }, [eventQueue, gtmLoaded, performClickTracking]);

  useEffect(() => {
    const permittedByCookiebot =
      !cookiebotEnabled || (cookiebotLoaded && consent?.statistics);
    const gtmSetup = analyticsEnabled && containerId && scriptNonce;

    const gtmCanLoad = permittedByCookiebot && gtmSetup && !gtmLoaded;

    if (gtmCanLoad) {
      const gtmOptions: GTMOptions = {
        containerId,
        auth,
        preview,
        env: environment,
        nonce: scriptNonce,
      };

      Wrapper.initialize(gtmOptions);

      const pageViewPayload: PageViewPayload = {
        sourceVersion,
        env: environment,
        pageType: analyticsPageType,
        gaParam: extractGaParameter(),
        currentUserUuid: currentUser?.uuid,
      };

      Wrapper.trackPageViewEvent(pageViewPayload);

      setGtmLoaded(true);
    }
  }, [
    analyticsEnabled,
    analyticsPageType,
    serverAnalyticsEvents,
    auth,
    consent,
    containerId,
    cookiebotEnabled,
    cookiebotLoaded,
    currentUser,
    environment,
    gtmLoaded,
    preview,
    scriptNonce,
    sourceVersion,
  ]);

  const enqueueEvent = (event: AnalyticsEvent) =>
    setEventQueue((events) => [...events, event]);

  const trackClickEvent = useCallback(
    (clickEvent: ClickEvent) => {
      if (gtmLoaded) {
        performClickTracking(clickEvent);
      } else {
        enqueueEvent(clickEvent);
      }
    },
    [gtmLoaded, performClickTracking],
  );

  const trackAuthEvent = useCallback(
    (authEvent: AuthEvent) => {
      if (gtmLoaded) {
        performAuthTracking(authEvent);
      } else {
        enqueueEvent(authEvent);
      }
    },
    [gtmLoaded],
  );

  const track: TrackedEventFunctions = useMemo(
    () => ({
      trackClickEvent,
      trackAuthEvent,
    }),
    [trackClickEvent, trackAuthEvent],
  );

  useEffect(() => {
    if (config.analytics?.track !== track && setConfig) {
      setConfig({ ...config, analytics: { track } });
    }
  }, [config, setConfig, track]);

  useEffect(() => {
    if (serverAnalyticsEvents && !processedServerEvents) {
      serverAnalyticsEvents.forEach((event) => {
        trackAuthEvent({
          type: "auth",
          eventName: event.eventName,
          method: event.method,
          userId: currentUser?.uuid || "",
        });
      });
      setProcessedServerEvents(true);
    }
  }, [
    serverAnalyticsEvents,
    trackAuthEvent,
    currentUser,
    processedServerEvents,
  ]);

  return <>{children}</>;
};

export default Provider;
