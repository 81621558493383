import React from "react";
import { createRoot } from "react-dom/client";

import RouteLoader from "../components/RouteLoader";

const loadRoute = (
  route: React.ComponentType,
  routeMetaData?: RouteMetaData,
) => {
  document.addEventListener("DOMContentLoaded", () => {
    const container = document.getElementById("root");
    const root = createRoot(container!);
    root.render(<RouteLoader route={route} routeMetaData={routeMetaData} />);
  });
};

export default loadRoute;
