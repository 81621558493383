import React, { useEffect } from "react";
import { atom, useAtom } from "jotai";
import { useConfig, useCspNonce } from "@envato/sso-common";
import { createGlobalStyle } from "styled-components";

type ConsentValues = {
  necessary: boolean;
  preferences: boolean;
  statistics: boolean;
  marketing: boolean;
};

export type CookiebotValues = {
  loaded: boolean;
  isOutsideEU?: boolean;
  consent?: ConsentValues;
};

const defaults: CookiebotValues = {
  loaded: false,
};

const cookiebotAtom = atom<CookiebotValues>(defaults);

export const useCookiebot = (): [
  CookiebotValues,
  (newState: CookiebotValues) => void,
] => {
  return useAtom(cookiebotAtom);
};

const CssOverrides = createGlobalStyle`
  /* Cookiebot CMP branding */
  a#CybotCookiebotDialogPoweredbyCybot,
  div#CybotCookiebotDialogPoweredByText {
    display: none !important;
  }

  /* Layout */
  #CybotCookiebotDialogDetailFooter {
    display: none !important;
  }

  /* Cookie detail list */
  .CybotCookiebotDialogDetailBodyContentCookieTypeTableContainer {
    display: none !important;
  }

  /* Cookies categories tabs */
  #CybotCookiebotDialogDetailBodyContentCookieContainerUnclassified {
    display: none !important;
  }

  /* Cookie category toggle */
  .CybotCookiebotDialogDetailBodyContentCookieContainerButton::before {
    display: none !important;
  }

  /* Cookie count */
  .CybotCookiebotDialogDetailBulkConsentCount {
    display: none !important;
  }
`;

const withCookiebot = <P extends object>(
  WrappedComponent: React.ComponentType<P>,
  initialState?: CookiebotValues,
) => {
  return (props: P) => {
    const scriptNonce = useCspNonce();
    const [{ cookiebotEnabled, cookiebotIds }] = useConfig();
    const [, setCookiebotConfig] = useAtom(cookiebotAtom);

    useEffect(() => {
      if (initialState) {
        setCookiebotConfig(initialState);
      }
    }, [setCookiebotConfig]);

    useEffect(() => {
      let current = true;
      const onCookiebotLoad = () => {
        if (current) {
          if (window.Cookiebot) {
            const { necessary, preferences, statistics, marketing } =
              window.Cookiebot.consent;

            setCookiebotConfig({
              loaded: true,
              consent: {
                necessary,
                preferences,
                statistics,
                marketing,
              },
            });
          }
        }
      };

      window.addEventListener("CookiebotOnLoad", onCookiebotLoad, true);

      if (
        cookiebotEnabled &&
        cookiebotIds &&
        scriptNonce &&
        !document.getElementById("Cookiebot")
      ) {
        const script = document.createElement("script");
        script.setAttribute("id", "Cookiebot");
        script.setAttribute("src", "https://consent.cookiebot.com/uc.js");
        script.setAttribute("data-cbid", cookiebotIds.default);
        script.setAttribute("type", "text/javascript");
        script.setAttribute("nonce", scriptNonce);
        script.setAttribute("data-testid", "cookiebotScript");
        script.setAttribute(
          "data-georegions",
          JSON.stringify({ region: "US", cbid: cookiebotIds["US"] }),
        );

        const head = document.head;
        if (head) {
          head.insertBefore(script, head.firstChild);
        }
      }

      return () => {
        current = false;
        window.removeEventListener("CookiebotOnLoad", onCookiebotLoad);
      };
    }, [cookiebotEnabled, cookiebotIds, setCookiebotConfig, scriptNonce]);

    return (
      <>
        <CssOverrides />
        <WrappedComponent {...props} />
      </>
    );
  };
};

export default withCookiebot;
