import { useEffect } from "react";

import { useConfig } from "@envato/sso-common";

import { ToastType } from "../Toast";
import { useToasts } from "../ToastsProvider";

const FLASH_DURATION = 10000;

const Flash = () => {
  const [{ flash }] = useConfig();

  const { showToast } = useToasts()!;

  useEffect(() => {
    Object.entries(flash).forEach(([type, message]) => {
      showToast({
        type: type as ToastType,
        message,
        duration: FLASH_DURATION,
      });
    });
  }, [flash, showToast]);

  return null;
};

export default Flash;
