import { sendAnalyticsEvent } from "@envato/gtm-analytics";

import { AuthEvent } from "@envato/sso-analytics";

const trackAuthEvent = (authEvent: AuthEvent) => {
  const payload = {
    eventType: "user",
    eventName: authEvent.eventName,
    method: authEvent.method,
    userAttributes: {
      userId: authEvent.userId,
    },
  };
  sendAnalyticsEvent(payload);
};

export default trackAuthEvent;
