import { ExperimentData } from "@envato/sso-common";

interface Location {
  href: string;
  pathname: string;
}

const scrubTokenRoutes = (pathname: string): string => {
  return ["password_resets", "sign_in_verification", "confirm"].reduce(
    (current, route) =>
      current.replace(new RegExp(`(.*/${route}/)(.*)$`), "$1*"),
    pathname,
  );
};

export const scrubLocation = ({ href, pathname }: Location): Location => {
  const scrubbedPath = scrubTokenRoutes(pathname);

  return {
    pathname: scrubbedPath,
    href: href.replace(pathname, scrubbedPath),
  };
};

export const convertExperiments = (
  experiments: Array<ExperimentData>,
): string => experiments.map((exp) => `${exp.id}.${exp.variant}`).join("!");

export const extractGaParameter = (): string | null => {
  const urlParams = new URLSearchParams(window.location.search);
  const _ga = urlParams.get("_ga");
  return _ga ? `_ga=${_ga}` : null;
};
