import React from "react";

import Provider from "./Provider";

const withGoogleTagManager = <P extends object>(
  WrappedComponent: React.ComponentType<P>,
) => {
  return (props: P) => {
    return (
      <Provider>
        <WrappedComponent {...props} />
      </Provider>
    );
  };
};

export default withGoogleTagManager;
