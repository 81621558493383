import React, { createContext } from "react";

const defaultRouteMetaData: RouteMetaData = {
  analyticsPageType: "account management",
};

const RouteMetaDataContext = createContext<RouteMetaData>(defaultRouteMetaData);

type RouteMetaDataProviderProps = {
  value?: RouteMetaData;
  children: React.ReactNode;
};

export const RouteMetaDataProvider = ({
  value = defaultRouteMetaData,
  children,
}: RouteMetaDataProviderProps) => {
  return (
    <RouteMetaDataContext.Provider value={value}>
      {children}
    </RouteMetaDataContext.Provider>
  );
};

export const useRouteMetaData = () => {
  return React.useContext(RouteMetaDataContext);
};
