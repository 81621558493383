import React from "react";

import ToastsProvider from "./ToastsProvider";
import Flash from "./Flash";

const withToasts = <P extends object>(
  WrappedComponent: React.ComponentType<P>,
) => {
  return (props: P) => {
    return (
      <ToastsProvider>
        <Flash />
        <WrappedComponent {...props} />
      </ToastsProvider>
    );
  };
};

export default withToasts;
