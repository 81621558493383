import React, { useState, useCallback, useContext } from "react";
import styled from "styled-components";

import { Stack } from "@envato/eds";
import { useConfig } from "@envato/sso-common";

import Toast, { ToastProps } from "./Toast";

const DEFAULT_TOAST_TIME = 1500;
const DEFAULT_TOAST_TIME_TEST = 100;

type ToastProviderType = {
  showToast: (toShow: ToastProps) => void;
  removeToast: (toRemove: ToastProps) => void;
};

const ToastContext = React.createContext<ToastProviderType | undefined>(
  undefined,
);

const ToastContainer = styled(Stack).attrs({
  as: "ol",
  spacing: "2x",
})`
  display: flex;
  flex-direction: column;
  align-items: center;
  list-style: none;

  padding: 0;

  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  margin: ${(props) => props.theme.spacing4x};

  z-index: ${(props) => props.theme.zIndexToast};

  @media (min-width: ${(props) => props.theme.breakMedium}) {
    right: initial;
  }
`;

export const useToasts = () => useContext(ToastContext);

interface ToastsProviderProps {
  children?: React.ReactNode;
}

const ToastsProvider: React.FC<ToastsProviderProps> = ({ children }) => {
  const [toasts, setToasts] = useState<ToastProps[]>([]);
  const [{ environment }] = useConfig();

  const showToast = useCallback(
    (toShow: ToastProps) => {
      const newToast = {
        id: `${toShow.type}${Date.now()}${toShow.message}`,
        duration:
          environment === "test" ? DEFAULT_TOAST_TIME_TEST : DEFAULT_TOAST_TIME,
        canDismiss: true,
        ...toShow,
      };

      setToasts((existing) => [...existing, newToast]);
    },
    [environment],
  );

  const removeToast = useCallback((toRemove: ToastProps) => {
    setToasts((existing) => existing.filter((t) => t.id !== toRemove.id));

    if (toRemove.onComplete) {
      toRemove.onComplete();
    }
  }, []);

  return (
    <ToastContext.Provider value={{ showToast, removeToast }}>
      {children}
      <ToastContainer>
        {toasts.map((t) => (
          <Toast key={t.id} toast={t} removeToast={removeToast} />
        ))}
      </ToastContainer>
    </ToastContext.Provider>
  );
};

export default ToastsProvider;
