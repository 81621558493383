import { marketTheme, placeitTheme, brandNeueTheme } from "@envato/sso-common";

const clientTheme = (appId?: string) => {
  const themeKey = appId;

  switch (themeKey) {
    case "market":
      return marketTheme;
    case "placeit":
      return placeitTheme;
    default:
      return brandNeueTheme;
  }
};

export default clientTheme;
