import React from "react";

import { RouteMetaDataProvider } from "./RouteMetaDataContext";

const withRouteMetaData = <P extends object>(
  WrappedComponent: React.ComponentType<P>,
  routeMetaData?: RouteMetaData,
) => {
  return (props: P) => (
    <RouteMetaDataProvider value={routeMetaData}>
      <WrappedComponent {...props} />
    </RouteMetaDataProvider>
  );
};

export default withRouteMetaData;
