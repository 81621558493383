import React, { useCallback, useEffect } from "react";
import styled, { keyframes } from "styled-components";

import {
  ConfirmationMessage,
  ErrorMessage,
  InformationMessage,
  WarningMessage,
} from "@envato/eds";

export type ToastType =
  | "info"
  | "notice"
  | "warning"
  | "error"
  | "confirmation";

export type ToastProps = {
  id?: string;
  duration?: number;
  type: ToastType;
  message?: string;
  children?: React.ReactNode;
  onComplete?: () => void;
  canDismiss?: boolean;
};

const fadeInOut = keyframes`
  0% {
    opacity: 0;
  }

  10% {
    opacity: 1;
  }

  90% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
`;

const ToastListItem = styled.li<{ duration: number }>`
  opacity: 0;
  animation: ${fadeInOut} ${(props) => props.duration}ms
    ${(props) => props.theme.transitionEasingLinear};
  width: 80vw;
  max-width: 400px;
`;

interface ToastViewProps {
  toast: ToastProps;
  removeToast: (toRemove: ToastProps) => void;
}

const Toast: React.FC<ToastViewProps> = ({ toast, removeToast }) => {
  useEffect(() => {
    setTimeout(() => {
      removeToast(toast);
    }, toast.duration);
  }, [removeToast, toast]);

  const handleDismiss = useCallback(() => {
    removeToast(toast);
  }, [removeToast, toast]);

  return (
    <ToastListItem duration={toast.duration as number}>
      {toast.type === "error" && (
        <ErrorMessage
          text={toast.message}
          onDismiss={handleDismiss}
          testId="toast"
        >
          {toast.children}
        </ErrorMessage>
      )}
      {(toast.type === "info" || toast.type === "notice") && (
        <InformationMessage
          text={toast.message}
          onDismiss={handleDismiss}
          testId="toast"
        >
          {toast.children}
        </InformationMessage>
      )}
      {toast.type === "warning" && (
        <WarningMessage
          text={toast.message}
          onDismiss={handleDismiss}
          testId="toast"
        >
          {toast.children}
        </WarningMessage>
      )}
      {toast.type === "confirmation" && (
        <ConfirmationMessage
          text={toast.message}
          onDismiss={handleDismiss}
          testId="toast"
        >
          {toast.children}
        </ConfirmationMessage>
      )}
    </ToastListItem>
  );
};

export default Toast;
